import React from 'react'
import Layout from '../../components/Layout'
import EmployeeRoll from '../../components/EmployeeRoll'
import Button from '../../components/Button'
import OurMission from '../../components/OurMission'
import PopQuote from '../../components/PopQuote'
import { themeStyles, colors, presets } from '../../utils/theme'
import IndexVentures from '../../img/about-us/IndexVentures.png'
import L2Ventures from '../../img/about-us/L2Ventures.png'
import BaselineVentures from '../../img/about-us/BaselineVentures.png'
import core from '../../img/about-us/core.png'
import OVO_Fund from '../../img/about-us/OVO_Fund.png'
import ThomvestVentures from '../../img/about-us/ThomvestVentures.png'
import transmedia_logo from '../../img/about-us/transmedia_logo.png'
import TrueVentures from '../../img/about-us/TrueVentures.png'
import Virgin from '../../img/about-us/Virgin.png'
import Mantis from '../../img/about-us/Mantis_logo.png'
import Accelerator from '../../img/about-us/Accelerator_logo.png'
import Avanta from '../../img/about-us/avanta_logo_Blue.svg'
import Morado from '../../img/about-us/Morado_VP_Logo.png'
import WorkPlay from '../../img/about-us/WorkPlay_logo.png'
import launchDay from '../../img/about-us/Launch-Day-Boat.jpg'
import camAndRich from '../../img/about-us/016.jpg'

const styles = {
  container: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
  investorLogo: {
    width: '120px',
    margin: 0,
  },
  investorLogoWrapper: {
    width: '200px',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: '100%',
    margin: '20px',
  },
  investorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '800px',
    margin: '0 auto',
  },
  centerQuoteContainer: {
    padding: '10px',
    paddingTop: '30px',
    border: `1px solid ${colors.grey}`,
    borderLeft: 0,
    borderRight: 0,
    maxWidth: '500px',
    margin: `20px auto`,
    marginBottom: '80px',
  },
  title: {
    textAlign: 'center',
    marginTop: '40px',
  },
  cultureDescription: {
    backgroundColor: colors.green,
    color: colors.white,
  },
  cultureDescriptionText: {
    width: '100%',
    maxWidth: '820px',
    margin: '0 auto',
    lineHeight: 1.5,
    padding: '30px',
    textAlign: 'center',
    [presets.Tablet]: {
      textAlign: 'left',
    }
  },
  cultureDetail: {
    backgroundColor: colors.tan,
  },
  cultureCTAContainer: {
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamImageHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    ...themeStyles.contentWidth,
  },
  teamImage: {
    height: '300px',
    margin: '10px',
  }
}

const investorLogos = [
  transmedia_logo,
  IndexVentures,
  OVO_Fund,
  TrueVentures,
  core,
  L2Ventures,
  ThomvestVentures,
  BaselineVentures,
  Mantis,
  Accelerator,
  Avanta,
  Morado,
  Virgin,
  WorkPlay,
]

const Investor = ({ src }) => (
  <div css={styles.investorLogoWrapper}>
    <img src={src} css={styles.investorLogo} alt='Investor Logo'/>
  </div>
)

const CenterQuote = () => (
  <div css={styles.centerQuoteContainer}>
    <p>
      We realized people were losing billions of dollars to mortgage lenders that were only focusing on interest rates.
    </p>
    <p>
      The <strong css={{fontWeight: 600}}>smart loan</strong> was created to help people stop losing money and own their financial future.
    </p>
  </div>
)

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout metadataTitle='LoanSnap - About Us' metadataDescription='LoanSnap - FAQ'>
        <OurMission />
        <div css={styles.container}>
          <CenterQuote />
          <EmployeeRoll />
          <h2 css={themeStyles.sectionHeader}>
            Our Investors
          </h2>
          <div css={styles.investorRow}>
            {investorLogos.map((l, i) => <Investor src={l} key={i} />)}
            <div css={[styles.investorLogoWrapper, {display: 'none', border: 'none', [presets.Desktop]: { display: 'flex'}}]} />
          </div>
        </div>
        <PopQuote />
        <h2 css={themeStyles.sectionHeader}>
          Our Culture
        </h2>
        <div css={styles.cultureDescription}>
          <div css={styles.cultureDescriptionText}>
            We're a group of <strong css={{fontWeight: 600}}>hard-working and passionate engineers, marketers, designers, and product managers.</strong> We care about our work and each other.
          </div>
        </div>
        <div css={styles.cultureDetail}>
          <div css={styles.teamImageHolder}>
            <img src={launchDay} css={styles.teamImage} alt='Team on Boat'/>
            <img src={camAndRich} css={styles.teamImage} alt='Team working' />
          </div>
          <div css={styles.cultureCTAContainer}>
            <p css={{ fontWeight: 600, fontSize: '18px'}}>
              Come help us change the mortgage lending experience!
            </p>
            <Button
              to='/careers'
              text='Join Us'
            />
          </div>
        </div>
      </Layout>
    )
  }
}
